<template>
    <el-dialog title="字典管理" :visible.sync="dict.show" width="600px" v-if="dict.show" append-to-body>
        <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item :title="item.dictName" :name="item.dictCode" v-for="item in dataList" :key="item.id">
                <el-table :data="tableList[item.dictCode]" style="width: 100%" stripe fit max-height="640">
                    <el-table-column align="center" label="字典名称" prop="dictLabel"></el-table-column>
                    <el-table-column align="center" label="字典值" prop="dictValue"></el-table-column>
                    <el-table-column align="center" label="创建人" prop="createBy"></el-table-column>
                    <el-table-column align="center" label="创建时间" prop="createTime"></el-table-column>
                    <el-table-column align="center" label="操作" fixed="right">
                        <template v-slot="scope">
<!--                            <el-button type="text" size="mini" icon="el-icon-edit-outline"-->
<!--                                       @click="handleUpdate(scope.row)">修改-->
<!--                            </el-button>-->
                            <!--                            <el-button type="text" size="mini" icon="el-icon-delete">删除</el-button>-->
                        </template>
                    </el-table-column>
                </el-table>
            </el-collapse-item>
        </el-collapse>
    </el-dialog>
</template>

<script>
import * as dictApi from "@/api/system/dictionary";

export default {
    name: "DictShow",
    props: {
        dict: {
            type: Object,
            default: {
                show: false,
                codes: null
            }
        }
    },
    components: {},
    data() {
        return {
            activeNames: [],
            dataList: [],
            tableList: {},
        }
    },
    mounted() {
        this.findList()
    },
    methods: {
        findList() {
            dictApi.list({dictParent: 0, codes: this.dict.codes}).then(res => {
                this.dataList = res.data
            })
        },
        handleChange(e, f) {
            let code = e[e.length - 1]
            dictApi.findChildrenByCode(code).then(res => {
                this.tableList[code] = res.data
            })
        },
    }
}
</script>

<style scoped>
.el-pagination {
    bottom: 10px;
    position: fixed;
}
</style>